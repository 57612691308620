<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          label-width="70px"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.phone_number')" prop="phoneNumber">
                <el-input
                  v-model.trim="formInline.phoneNumber"
                  maxlength="11"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.type')" prop="msgType">
                <el-select v-model.trim="formInline.msgType" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.type"
                    v-for="value in msgType"
                    :key="value.type"
                  ></el-option>
                </el-select>
              </el-form-item>
              <br />
              <el-form-item label="时间">
                <el-date-picker
                  :editable="false"
                  v-model.trim="dateRange"
                  type="datetimerange"
                  placeholder="请选择时间范围"
                  :default-time="['00:00:00', '23:59:59']"
                  @change="dateFmt(dateRange)"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm('formInline')"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                @click="sendSmsClick"
                icon=" el-icon-message"
                v-if="$route.meta.authority.button.send"
                >发送消息</el-button
              >
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <el-dialog title="发送短信消息" :visible.sync="centerDialogVisible" width="60%" center>
        <el-form :model="formInline2" :rules="rules" ref="formInline2" label-width="100px">
          <el-form-item label="发送对象">
            <span>指定用户</span>
          </el-form-item>
          <el-form-item label="" prop="phoneNumber">
            <el-input
              type="textarea"
              placeholder="请输入手机号，多个手机号用，分隔。例：13400000001，13400000002"
              v-model="formInline2.phoneNumber"
            ></el-input>
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <el-input type="textarea" maxlength="150" v-model="formInline2.content"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('formInline2')">发送</el-button>
            <el-button @click="centerDialogVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
let date = new Date();
date.setHours(0);
date.setMinutes(0);
date.setSeconds(0);
let startTime = dateFormat(date);
date.setHours(23);
date.setMinutes(59);
date.setSeconds(59);
let endTime = dateFormat(date);
import { dateFormat } from "@/common/js/public.js";
export default {
  name: "shortMessageManage",
  data() {
    return {
      msgType: [],
      centerDialogVisible: false,
      dateRange: [new Date(startTime), new Date(endTime)],
      rowData: "",
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      roleList: [],
      loading: false,
      detailFlag: false,
      rsTypeList: {},
      tableCols: [
        {
          prop: "phoneNumber",
          label: "手机",
          width: "120",
        },
        {
          prop: "msgType",
          label: "消息类型",
          width: "120",
          formatter: (row, column) => {
            return this.msgType.find((v) => {
              return row.msgType == v.type;
            }).desc;
          },
        },
        {
          prop: "content",
          label: "内容",
          width: "",
        },
        {
          prop: "operatorName",
          label: this.$t("list.Operator"),
          width: "120",
        },
        {
          prop: "createdTime",
          label: "时间",
          width: "",
        },
      ],
      rules: {
        phoneNumber: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (!/^\d{11}([，|,]\d{11})*$/.test(value)) {
                callback(new Error("手机号格式不正确"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        content: [
          { required: true, message: "请输入发送内容", trigger: "blur" },
          { min: 3, max: 50, message: "长度在 3 到 50 个字符", trigger: "blur" },
        ],
      },
      tableData: [],
      formInline: {
        msgType: "",
        title: "",
        transitionType: "",
        bannerStatus: "",
        startTime: startTime,
        endTime: endTime,
      },
      formInline2: {
        phoneNumber: "",
        content: "",
      },
    };
  },
  methods: {
    sendSmsClick() {
      this.$router.push({ path: "/sendSMS" });
    },
    dateFmt(data) {
      if (data && data.length === 2) {
        let startTime = dateFormat(data[0], "yyyy-MM-dd HH:mm:ss");
        let endTime = dateFormat(data[1], "yyyy-MM-dd HH:mm:ss");
        this.formInline.startTime = startTime;
        this.formInline.endTime = endTime;
      } else {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
      }
    },
    // 选择每行数据
    getRowData(data) {
      this.rowData = data;
    },
    // 给指定用户发送短信
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sendSms();
          this.centerDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    // 下来菜单
    getSpinner() {
      this.$axios.get("/acb/2.0/spinner").then((res) => {
        this.msgType = res.value.messageType;
        for (var i = 0; i < this.msgType.length; i++) {
          if (this.msgType[i].type == 7) {
            this.msgType.splice(i, 1);
          }
        }
        this.formInline.msgType = "";
      });
    },
    // 发送短信
    sendSms() {
      this.$axios
        .post("/acb/2.0/notice/sms", {
          data: {
            phoneNumber: this.formInline2.phoneNumber,
            content: this.formInline2.content,
          },
        })
        .then((res) => {
          this.formInline2.phoneNumber = "";
          this.formInline2.content = "";
          this.$alert("任务发送成功", {
            callback: (action) => {
              this.searchData();
            },
          });
        });
    },
    // 切换状态
    bannerStatus() {},
    // 操作细则
    handleCommand(command) {
      if (command == 1) {
        this.$router.push({ path: "/bannerManageDetail", query: this.rowData });
      } else if (command == 2) {
        this.$router.push({ path: "/bannerManage-AE", query: this.rowData });
      } else if (command == 3) {
      }
    },
    setSearch() {},
    resetForm(formName) {
      this.formInline = {
        msgType: "",
        title: "",
        transitionType: "",
        bannerStatus: "",
        startTime: startTime,
        endTime: endTime,
      };
      this.dateRange = [new Date(startTime), new Date(endTime)];
      this.$refs[formName].resetFields();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.tableData = [];
      this.$axios
        .get("/acb/2.0/notice/smsList", {
          data: {
            phoneNumber: this.formInline.phoneNumber,
            msgType: this.formInline.msgType,
            beginTime: this.formInline.startTime,
            endTime: this.formInline.endTime,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.total = res.value.total * 1 || 0;
          this.tableData = res.value.list;
        });
    },
  },
  components: {},
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  activated() {
    // console.log("-->", this.$route.meta.authority);
    if (sessionStorage.searchFlag == 1) {
      this.formInline = JSON.parse(sessionStorage.searchData);
    }
    this.searchData();
    this.getSpinner();
  },
  created() {
    if (sessionStorage.searchFlag == 1) {
      this.formInline = JSON.parse(sessionStorage.searchData);
    }
    this.searchData();
    this.getSpinner();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
